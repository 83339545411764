import React, { useState } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import Layout from "../layout/Layout";
import ProductFilters from "../components/ProductFilters";
import useGetAllBanners from "../hooks/banners/useGetAllBanners";
import BreadCrumbs from "../components/common/breadcrumbs/BreadCrumbs";
import useSearch from "../hooks/searchspring/useSearch";
import ProductSortFilter from "../components/searchSpring/ProductSortFilter";
import ProductListing from "../components/products/ProductListing";
import Banner from "../components/banner/Banner";
import Pagination from "../components/common/Pagination";
import { SearchContext } from "../context/SearchContext";

const BrandPageTemplate = ({ pageContext, location }) => {
  const { top_banner, bottom_banner } = useGetAllBanners(
    "brand_page",
    pageContext.brandId
  );
  const [view, setProductView] = useState("grid");
  const brandName = pageContext.name;
  const brandFilter = {
    [`filter.brand`]: brandName,
  };
  const type = "brand";

  const formik = useFormik({
    initialValues: { sortBy: "" },
    onSubmit: (values) => {
      console.log("filters", values);
    },
  });

  const {
    products,
    sortingOptions,
    isLoading,
    pagination,
    handleSortOption,
    breadcrumbs,
    facets,
    handleFilterChange,
    filterSummary,
    handleRemoveFilter,
    handlePageChange,
    applyFilter,
    handlePriceChange,
    onPriceReset,
  } = useSearch(formik.values["sortBy"], brandFilter, type);

  const { breadcrumbTitle } = breadcrumbs;
  const { page, totalPages } = pagination;

  const contextValues = {
    isLoading,
    products,
    facets,
    view,
    filterSummary,
    sortingOptions,
    setProductView,
    handleSortOption,
    handleFilterChange,
    handleRemoveFilter,
    applyFilter,
    handlePriceChange,
    onPriceReset,
  };

  return (
    <SearchContext.Provider value={contextValues}>
      <div className="page-wrapper">
        <div className="page-heading-section text-center">
          <BreadCrumbs location={location} title={breadcrumbTitle} />
        </div>
        <div className="container">
          <div className="row flex flex-wrap page-layout">
            <div className="col page-sidebar">
              <ProductFilters />
            </div>
            <div className="col page-content">
              <Banner banner={top_banner} />

              <div className="page-action-bar flex justify-space vertical-middle">
                <ProductSortFilter />
              </div>
              <ProductListing />
              <Pagination
                className="pagination-bar"
                currentPage={page}
                totalCount={totalPages}
                onPageChange={(page) => handlePageChange(page)}
              />
              <Banner banner={bottom_banner} />
            </div>
          </div>
        </div>
      </div>
    </SearchContext.Provider>
  );
};

BrandPageTemplate.propTypes = {
  title: PropTypes.string,
  location: PropTypes.object,
  pageContext: PropTypes.object,
};

function BrandPage({ location, pageContext }) {
  return (
    <Layout>
      <BrandPageTemplate location={location} pageContext={pageContext} />
    </Layout>
  );
}

BrandPage.propTypes = {
  location: PropTypes.object,
  pageContext: PropTypes.object,
};

export const Head = ({ pageContext }) => {
  return (
    <>
      <title>{pageContext.pageTitle}</title>
      <meta name="description" content={pageContext.metaDescription} />
    </>
  );
};

export default BrandPage;
